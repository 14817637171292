<template>
  <div class="pannel" v-loading="loading">
    <div class="pannel-con">
      <div class="shijuan-block">
        <div class="shijuan-title" v-if="type == 2">每日一练</div>
        <div class="shijuan-title" v-if="type == 1">
          {{ detail.courseName }}-{{ detail.chapterName }}
        </div>
        <div class="content" v-if="!isSubmit">
          ◇ 总共{{ subjectList.length }}题 <br />
          ◇ 试卷来源：本站
        </div>
        <div class="content" v-else>试卷已提交</div>
      </div>
      <div class="block">
        <div class="shiti-block">
          <div>
            <div class="warning">
              试卷含有填空题、简答题，请自行参考解析评分
            </div>
            <div v-for="(item, index) of subList" :key="index">
              <que-car :isPoints="false" :start="start" :isSubmited="isSubmit" :detail="item" @task="getNum" />
            </div>
          </div>
          <div class="start-mask" v-if="!start">
            <div class="text">已暂停</div>
          </div>
        </div>
        <!-- 左侧 -->
        <div class="shiti-function">
          <div class="control">
            <div class="control-header">
              <div class="timebox">
                <i class="iconfont icon-shijian"></i>
                答题时间
                <span :class="start ? 'countdown' : 'red countdown'" v-if="time">
                  {{ time.hours > 9 ? time.hours : "0" + time.hours }}:{{
                    time.minutes > 9 ? time.minutes : "0" + time.minutes
                  }}:{{ time.seconds > 9 ? time.seconds : "0" + time.seconds }}
                </span>
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="time-pause" @click="StartOrEnd()">
                <i class="iconfont" :class="start ? 'icon-bofang1' : 'icon-bofang'"></i>
                <span>{{ start ? "暂停" : "开始" }}</span>
              </div>
            </div>
            <div class="back pointer" @click="$router.back()">
              <i class="iconfont icon-fanhui"></i>返回上页
            </div>
          </div>
          <!-- 答题卡 -->
          <div class="sheet numberPannel">
            <div class="sheet-header">
              答题卡
              <span class="num">{{ complete }}/{{ subjectList.length }}</span>
            </div>
            <!-- 试题类型 -->
            <div class="sheet-block">
              <div class="T-Group" v-for="(item, index) of 6" :key="index">
                <div v-if="TiXing[index] > 0">
                  <div class="t-title">
                    {{ queType[index] }}
                    <span class="t-name">共{{ TiXing[index] }} 题</span>
                  </div>
                  <div class="t-numberbox">
                    <div v-for="(shi, shiIndex) of subjectList" :key="shiIndex">
                      <div v-if="shi.questionType == index">
                        <!-- 提交以后 -->
                        <div v-if="isSubmit" class="t-item" :class="Number(shi.isCorrect) == 0 ? 't-error' : 't-sucess'
                          " @click="jumpShiTi(shiIndex + 1)">
                          {{ shiIndex + 1 }}
                        </div>
                        <!-- 未提交 -->
                        <div v-else class="t-item" :class="index == 1
                            ? shi.questionUserAnswer &&
                              shi.questionUserAnswer.length > 0
                              ? 't-on'
                              : ''
                            : shi.questionUserAnswer
                              ? 't-on'
                              : ''
                          " @click="jumpShiTi(shiIndex + 1)">
                          {{ shiIndex + 1 }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 交卷 -->
            <div class="btnlist">
              <el-button class="submitBtn" v-if="!isSubmit" type="danger" @click="ShowAnswerChange">
                交卷
              </el-button>
              <!--   <el-button class="submitBtn" v-else type="danger" @click="submitShiJuan">重新答题</el-button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo } from "@/api/cookies";
import { AnswerClient } from "@/api/task/answerClient";
import QueCar from "../queCar.vue";
let answerClient = new AnswerClient();
import { queType } from "@/api/emun";
export default {
  components: {
    QueCar,
    /* ExamFeedBack, ExamAnalysis */
  },
  data() {
    return {
      queType: queType, //试题类型
      type: 1, //1:考点 2：每日一练
      detail: {}, //考点详情
      Id: "", //试卷ID
      userInfo: {},
      isSubmit: false, //是否显示解析
      subjectList: [], //题目列表//提交时用勇于显示题号
      subList: [], //题目列表-提交时用
      /* 计时器 */
      start: true, //是否计时
      timeEnd: "",
      time: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      /* 做题数 */
      complete: 0,
      /* 加载中 */
      loading: false,
      num: 0,
      /* 题型 */
      TiXing: [0, 0, 0, 0, 0, 0],
    };
  },

  async created() {
    this.Id = this.$route.query.Id ? String(this.$route.query.Id) : null;
    this.type = this.$route.query.type ? Number(this.$route.query.type) : 1;

    this.userInfo = getInfo();
    this.getShitiList();
    this.timeInterval();
    //滚动条监控  答题卡 滚动到顶部悬浮
    $(document).scroll(function () {
      // 滚动到上面  隐藏
      $(window).scroll(function () {
        let top = $(window).scrollTop();
        if (top >= 400) {
          $(".shiti-function").addClass("fixed-card");
        } else {
          $(".shiti-function").removeClass("fixed-card");
        }
      });
    });
  },
  methods: {
    //计时器
    timeInterval() {
      if (!this.isSubmit) {
        this.timeEnd = setInterval(() => {
          //回掉函数开始计时了
          this.time.seconds++;
          if (this.time.seconds == 60) {
            this.time.seconds = 0;
            this.time.minutes++;
          }
          if (this.time.minutes == 60) {
            this.time.minutes = 0;
            this.time.hours++;
          }
        }, 1000);
      }
    },
    //获取试卷列表
    async getShitiList() {
      this.loading = true;

      if (!this.isSubmit) {
        if (this.type == 1) {
          await answerClient.getPcPlaceDetails(this.Id).then((res) => {
            if (res.code == 0) {
              this.Splicing(res.data.placeSubjects, true);
              this.detail = res.data.Place;
            }
          });
        } else {
          await answerClient.selectRandomList(5).then((res) => {
            if (res.code == 0) {
              this.Splicing(res.data, true);
            }
          });
        }
      }
      this.loading = false;
    },
    /* 试题拼接 */
    Splicing(list, ans) {
      /* ans  是否是提交显示的 */
      let shiti = [];
      for (let item of list) {
        item.questionType = Number(item.questionType);
        if (item.questionType == 0 || item.questionType == 1) {
          let data = JSON.parse(item.questionOption);
          let sub = [];
          for (let i in data) {
            let val = {
              val: data[i],
              num: String.fromCharCode(Number(i) + 65),
            };
            sub.push(val);
          }
          item.optionList = sub;
          if (ans && item.questionType == 1) {
            item.questionUserAnswer = [];
          }
          if (!ans && item.questionType == 1) {
            item.questionUserAnswer = item.questionUserAnswer.split(",");
          }
          shiti.push(item);
        } else if (item.questionType == 2) {
          item.optionList = [
            { val: "对", num: "A" },
            { val: "错", num: "B" },
          ];
          shiti.push(item);
        } else if (item.questionType == 5) {
          if (item.childSubject) {
            for (let chil of item.childSubject) {
              chil.questionType = Number(chil.questionType);
              if (chil.questionType == 0 || chil.questionType == 1) {
                let data = JSON.parse(chil.questionOption);
                let sub = [];
                for (let i in data) {
                  let val = {
                    val: data[i],
                    num: String.fromCharCode(Number(i) + 65),
                  };
                  sub.push(val);
                }
                chil.optionList = sub;
                if (ans && chil.questionType == 1) {
                  chil.questionUserAnswer = [];
                }
                if (!ans && chil.questionType == 1) {
                  chil.questionUserAnswer = chil.questionUserAnswer.split(",");
                }
              } else if (chil.questionType == 2) {
                chil.optionList = [
                  { val: "对", num: "A" },
                  { val: "错", num: "B" },
                ];
              }
            }
            shiti = shiti.concat(item.childSubject);
          }
        } else {
          shiti.push(item);
        }
      }
      this.subjectList = shiti;
      this.subList = list; //提交时用  同数据源数据会一起变化
      //添加 字段
      for (let i in this.subjectList) {
        let item = this.subjectList[i];
        item.indexVal = Number(i) + 1;
        this.TiXing[item.questionType]++;
      }
    },
    /* 答案填写 */
    isChoose(item, val) {
      if (item.questionType === 0 || item.questionType === 2) {
        if (this.subjectIndex < this.subjectList.length - 1) {
          this.subjectIndex += 1;
        }
      }
    },

    //开始or暂停
    StartOrEnd() {
      if (this.isSubmit) {
        return;
      }
      if (this.start) {
        clearTimeout(this.timeEnd); //清除
        this.start = false;
      } else {
        this.timeInterval();
        this.start = true;
      }
      this.$forceUpdate();
    },
    /* 交卷 */
    submitShiJuan() { },
    /* 计算做题数 */
    async getNum() {
      this.complete = 0;
      for (let item of this.subjectList) {
        if (item.questionType == 1) {
          if (item.questionUserAnswer.length > 0) {
            await this.complete++;
          }
        } else {
          if (item.questionUserAnswer) {
            await this.complete++;
          }
        }
      }
      this.$forceUpdate();
    },
    //提交
    async ShowAnswerChange() {
      let that = this;
      this.StartOrEnd();
      if (this.userInfo != null) {
        if (this.subjectList.length - this.complete > 0) {
          this.$confirm("检测到您有未答的题目，是否确认提交？", "提交", {
            confirmButtonText: "提交",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              that.postEvery();
            })
            .catch(() => {
              this.StartOrEnd();
            });
        } else {
          this.$confirm("确认交卷吗？", "提交", {
            confirmButtonText: "提交",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              that.postEvery();
            })
            .catch(() => {
              this.StartOrEnd();
            });
        }
      }
    },
    //提交答案
    async postEvery() {
      /* 考点提交  */
      if (this.type == 1) {
        let data = await {
          examinationPlaceId: this.Id,
          questionSubjectPaperVoList: this.subjectList,
          answerTimes:
            (this.time.hours > 0 ? this.hours * 60 * 60 : 0) +
            (this.time.minutes > 0 ? this.minutes * 60 : 0) +
            this.time.seconds,
        };
        await answerClient.getKdSubjectAnswer(data).then((res) => {
          if (res.code == 0) {
            this.StartOrEnd();
            clearTimeout(this.timeEnd); //清除
            this.isSubmit = true;
            this.Splicing(
              res.data.PlaceSubject.questionSubjectPaperVoList,
              false
            );
          }
        });
      } else {
        let data = await {
          questionSubjectPaperVoList: this.subjectList,
          answerTimes:
            (this.time.hours > 0 ? this.hours * 60 * 60 : 0) +
            (this.time.minutes > 0 ? this.minutes * 60 : 0) +
            this.time.seconds,
        };
        await answerClient.addRandomSubjectAnswer(data).then((res) => {
          if (res.code == 0) {
            this.StartOrEnd();
            clearTimeout(this.timeEnd); //清除
            this.isSubmit = true;
            this.Splicing(
              res.data.PlaceSubject.questionSubjectPaperVoList,
              false
            );
          }
        });
      }
    },
    //跳转试题
    jumpShiTi(id) {
      $("html, body").animate(
        {
          scrollTop: $("#" + id).offset().top,
        },
        400
      );
    },
  },
};
</script>
<style lang="less" scoped>
.pannel {
  background-color: #f0f0f0;
  padding-bottom: 20px;

  .pannel-con {
    width: 1200px;
    margin: auto;
    overflow: hidden;
    margin-bottom: 48px;

    .shijuan-block {
      position: relative;
      width: 1200px;
      margin: auto;
      padding: 20px 30px;
      margin-bottom: 20px;
      background-color: #fff;

      margin-top: 30px;

      .shijuan-type {
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 14px;

        i {
          font-size: 20px;
          margin-right: 5px;
        }
      }

      .shijuan-title {
        text-align: center;
        font-size: 22px;
        margin: 0;
        padding: 2px 0px 27px;
        border-bottom: 1px dashed #e0e3e9;

        .grade {
          position: absolute;
          left: 40px;
          top: 0px;
          width: 150px;
          height: 80px;
          font-size: 50px;
          font-family: qiantumakeshouxieti;
          font-weight: 400;
          line-height: 80px;
          color: #ff5104;
        }
      }

      .content {
        font-size: 16px;
        color: #666;
        line-height: 2;
        padding-left: 60px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    .warning {
      background-color: #fff9e9;
      border: 1px dashed #ffeec4;
      text-align: center;
      line-height: 2.3;
      font-size: 16px;
      color: #ed7321;
    }

    .block {
      width: 1200px;
      margin: auto;
      overflow: hidden;

      .shiti-block {
        float: left;
        width: 910px;
        position: relative;
      }

      // <!-- 左侧 -->
      .shiti-function {
        width: 270px;
        float: right;
        min-height: 300px;

        .control {
          background-color: #fff;
          border: 1px solid #e0e3e9;
          margin-bottom: 10px;

          .control-header {
            overflow: hidden;
            background-color: #fff;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            line-height: 16px;
            color: #333333;
            border-bottom: 1px solid #e0e3e9;

            display: flex;
            align-items: center;

            .iconfont {
              font-size: 15px;
              color: #000;
              margin-right: 10px;
            }

            .timebox {
              font-size: 14px;
              display: block;
              width: auto;
              overflow: hidden;
              padding: 9px 10px;

              .countdown {
                color: #008000;
                font-weight: bold;
              }

              .red {
                color: red !important;
              }
            }

            .time-pause {
              cursor: pointer;
            }
          }
        }

        .sheet {
          background-color: #fff;
          border: 1px solid #e0e3e9;

          .sheet-header {
            background-color: #fff;
            border-bottom: 1px solid #e0e3e9;
            overflow: hidden;
            padding: 15px;
            cursor: default;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            line-height: 16px;
            color: #333333;

            .num {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              line-height: 14px;
              color: #f67e39;

              margin-left: 14px;
            }
          }

          .sheet-block {
            padding: 15px;
            max-height: 360px;
            overflow-y: auto;

            .T-Group {
              margin-bottom: 10px;

              .t-title {
                font-size: 12px;
                font-family: MicrosoftYaHei;
                line-height: 32px;
                color: #333;

                .t-name {
                  color: #a8abbe;
                }
              }

              .t-numberbox {
                margin: 0;
                padding: 0;
                list-style: none;
                margin-top: 10px;
                line-height: 30px;
                margin-right: 0;
                margin-left: 0;
                font-size: 14px;
                color: #333333;
                display: flex;

                .t-item {
                  width: 30px;
                  height: 26px;
                  background: #ffffff;
                  border: 1px solid #d6d6d6;

                  border-radius: 3px;
                  font-size: 16px;
                  font-family: MicrosoftYaHei;
                  line-height: 24px;
                  color: #333333;

                  text-align: center;
                  margin-right: 11px;
                  cursor: pointer;

                  &:hover {
                    background-color: #ee491f;
                    color: #f5deb3;
                  }
                }

                .t-on {
                  background-color: #ee491f;
                  color: #f5deb3;
                }

                .t-error {
                  background-color: #ef0d0d;
                  color: #fff;
                }

                .t-sucess {
                  background-color: #00cd85;
                  color: #fff;
                }
              }
            }
          }

          .btnlist {
            padding: 15px;

            .submitBtn {
              width: 232px;
              height: 45px;
              background: linear-gradient(90deg, #f15717 0%, #f68f64 100%);

              border-radius: 6px;
              font-size: 16px;
              font-family: MicrosoftYaHei;
              line-height: 16px;
              color: #ffffff;

              text-align: center;
              cursor: pointer;
            }
          }
        }
      }

      /*  */
      .fixed-card {
        position: fixed;
        top: 0px;
        right: calc((100% - 1200px) / 2);
      }
    }
  }
}

/* 暂停 */
.start-mask {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
  color: #fff;
  font-size: 30px;
  background: rgba(0, 0, 0, 0.23);

  .text {
    text-align: center;
    color: #fff;
    width: 910px;
    position: fixed;
    bottom: 50vh;
    /* margin-top: 50px; */
    z-index: 10;
  }
}

/*  */
.back {
  width: 112px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #a8abbe;

  border-radius: 17px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  line-height: 32px;
  color: #333333;

  text-align: center;
  margin: 16px 0px 16px 18px;
}
</style>
